var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{staticClass:"mx-auto",staticStyle:{"max-width":"400px"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('div',{staticClass:"text-center mb-5"},[_c('h1',[_vm._v(_vm._s(_vm.$t("resetPassword.heading")))]),_c('div',{staticClass:"subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t("resetPassword.subheading"))+" ")])]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('password-field',{attrs:{"attrs":{
          label: _vm.$t('labels.password'),
          rules: [_vm.rule.required, _vm.rule.password],
        }},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('password-field',{attrs:{"attrs":{
          label: _vm.$t('labels.passwordConfirm'),
          rules: [
            _vm.rule.required,
            _vm.rule.same(_vm.$t('labels.password'), _vm.formData.password) ],
        }},model:{value:(_vm.formData.password_confirmation),callback:function ($$v) {_vm.$set(_vm.formData, "password_confirmation", $$v)},expression:"formData.password_confirmation"}})],1)],1),_c('small',{staticClass:"d-block text-right"},[_c('router-link',{attrs:{"to":{ name: 'auth.login' }}},[_vm._v(" "+_vm._s(_vm.$t("buttons.returnToLogin"))+" ")])],1),_c('api-response',{staticClass:"mt-5",attrs:{"response":_vm.formResponse}}),_c('v-btn',{staticClass:"mt-8",attrs:{"color":"primary","type":"submit","disabled":!_vm.formValid,"loading":_vm.$store.getters['auth/loading'].resetPassword,"block":""}},[_vm._v(" "+_vm._s(_vm.$t("buttons.setNewPassword"))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }